const body = document.querySelector("body");
const header = document.querySelector(".header");
const logo = document.querySelector(".main-nav__logo");
const toggleBtn = document.querySelector(".hamburger");
const navMenu = document.querySelector(".main-nav__menu");
const links = document.querySelectorAll(".main-nav__link");

//***** SHOW NAV MENU - PREVENT SCROLL
toggleBtn.addEventListener("click", () => {
  showMenuFunctions();
});

const showMenuFunctions = () => {
  toggleBtn.classList.toggle("show-menu");
  logo.classList.toggle("active");
  body.classList.toggle("overflow-hidden");
  navMenu.classList.toggle("active");
};

links.forEach((link) => {
  link.addEventListener("click", () => {
    navMenu.classList.remove("active");
    body.classList.remove("overflow-hidden");
    toggleBtn.classList.remove("show-menu");
    logo.classList.remove("active");
  });
});

//***** SHOW TOP BTN WHEN SCROLL *****
const showTopScrollBtn = () => {
  const scrollTopBtn = document.querySelector(".scroll-top");

  window.scrollY > 200
    ? scrollTopBtn.classList.add("show-scroll")
    : scrollTopBtn.classList.remove("show-scroll");
};

window.addEventListener("scroll", showTopScrollBtn);

//***** SHOW-HIDE TOP SCROLL BAR *****

let lastScroll = 0;

window.addEventListener("scroll", () => {
  const currentScroll = window.scrollY;

  if (currentScroll <= 0) {
    header.classList.remove("scroll-up");
  }

  if (currentScroll > lastScroll && !header.classList.contains("scroll-down")) {
    header.classList.remove("scroll-up");
    header.classList.add("scroll-down");
  }
  if (currentScroll < lastScroll && header.classList.contains("scroll-down")) {
    header.classList.remove("scroll-down");
    header.classList.add("scroll-up");
  }

  lastScroll = currentScroll;
});
